import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { useState } from "react"
import { useBand } from "../../../../Provider/Band/Band"
import uuid from "react-uuid"
import { UserPreferences } from "../../../../Types/user.type"
import { useLabel } from "../../../../Provider/Label/Label"
import { toast } from "react-toastify"
import { Controller, useForm } from "react-hook-form"
import { useMobile } from "../../../../Provider/Theme/Mobile"

interface Props {
   open: boolean
   onClose: () => void
}

export const ManagerLabels = ({ onClose, open }: Props) => {
   const [currentBand, setCurrentBand] = useState<number>()
   const { myBands } = useBand()
   const { labels: labelsProvider, onRefreshLabel } = useLabel()
   const { mobile } = useMobile()
   const [preferences, setPreferences] = useState({} as UserPreferences)

   const {
      handleSubmit,
      control,
      setValue,
      getValues,
      watch

   } = useForm<any>()

   const onSubmit = (data: any) => {
      const formData = getValues();

      const preferencesAux = {
         labelsDisplayed: [] as any[]
      };

      preferencesAux.labelsDisplayed = Object.keys(formData)
         .filter(key => key.startsWith('label-id_') && formData[key])
         .map(key => ({ id: parseInt(key.replace('label-id_', '')) }));

      localStorage.setItem(`@BandSchedule:user_preferences:band-${currentBand}`, JSON.stringify(preferencesAux));

      toast.success('Preferências atualizadas com sucesso.')
      onRefreshLabel()
      onClose()

   };

   useEffect(() => {
      if (currentBand) {
         const aux = localStorage.getItem(`@BandSchedule:user_preferences:band-${currentBand}`)
         setPreferences(aux && aux != null ? JSON.parse(aux) : { labelsDisplayed: [] } as UserPreferences)
      }
   }, [currentBand])

   useEffect(() => {
      if (preferences && preferences.labelsDisplayed && getValues()) {

         const formData = getValues()
         if (preferences.labelsDisplayed.length == 0) {
            Object.keys(formData).map(key => {
               setValue(key, true)
            })
         } else {
            Object.keys(formData).map(key => {
               setValue(key, false)
            })
            preferences.labelsDisplayed.map(key => setValue(`label-id_${key.id}`, true))
         }
      }
   }, [preferences])

   return (
      <>
         <Box
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ overflow: "auto", marginTop: '32px' }}
         >
            <Typography>Escolha uma banda:</Typography>
            <>
               <Box>
                  <FormControl fullWidth={true}>
                     <InputLabel id="demo-simple-select-helper-label">Banda *</InputLabel>
                     <Select
                        sx={{ minWidth: 270 }}
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-error"
                        label="Banda"
                        value={currentBand}
                        onChange={e => {
                           setCurrentBand(e.target.value as number)
                        }}
                     >
                        {myBands.map(band => {
                           if (!band.is_deleted) {
                              return (
                                 <MenuItem value={band.id} key={uuid()}>
                                    {band.name}
                                 </MenuItem>
                              )
                           }
                           return <></>
                        })}
                     </Select>
                  </FormControl>
               </Box>

               {currentBand && preferences && preferences.labelsDisplayed ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                     {labelsProvider.map(label => {
                        if (!label.is_deleted) {
                           const isChecked = preferences.labelsDisplayed?.length == 0 ? true : preferences.labelsDisplayed?.some(displayedLabel => displayedLabel.id === label.id);
                           return (
                              <Stack direction={mobile ? "column" : "row"} spacing={2} key={`label-${label.id}`}>
                                 <FormControlLabel
                                    control={
                                       <Controller
                                          name={`label-id_${label.id}`}
                                          control={control}
                                          render={({ field: props }) => (

                                             <Checkbox
                                                {...props}
                                                checked={props.value}
                                                onChange={e => props.onChange(e.target.checked)}
                                                defaultChecked={isChecked}
                                             />
                                          )}
                                       />
                                    }
                                    label={label.title}
                                 />
                              </Stack>
                           )
                        }
                        return <></>
                     })}
                     <Button type='submit' variant='contained'>Salvar</Button>
                  </form>
               ) : <></>}

            </>
         </Box>
      </>
   )
}

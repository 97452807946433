export const monthList = [
  { id: 1, label: "JAN" },
  { id: 2, label: "FEV" },
  { id: 3, label: "MAR" },
  { id: 4, label: "ABR" },
  { id: 5, label: "MAI" },
  { id: 6, label: "JUN" },
  { id: 7, label: "JUL" },
  { id: 8, label: "AGO" },
  { id: 9, label: "SET" },
  { id: 10, label: "OUT" },
  { id: 11, label: "NOV" },
  { id: 12, label: "DEZ" },
]

export function getColorContrast(cor) {
  function converterParaHex(cor) {
    if (/^#[0-9A-F]{6}$/i.test(cor)) {
      return cor.substring(1);
    }

    if (/^rgb\(/i.test(cor)) {
      const valores = cor.match(/\d+/g);
      const r = parseInt(valores[0]);
      const g = parseInt(valores[1]);
      const b = parseInt(valores[2]);
      return ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');
    }

    if (/^rgba\(/i.test(cor)) {
      const valores = cor.match(/\d+/g);
      const r = parseInt(valores[0]);
      const g = parseInt(valores[1]);
      const b = parseInt(valores[2]);
      return ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');
    }

    if (/^#[0-9A-F]{8}$/i.test(cor)) {
      return cor.substring(1, 7);
    }

    return null;
  }

  const corHex = converterParaHex(cor);

  if (corHex === null) {
    return true;
  }

  const r = parseInt(corHex.substring(0, 2), 16);
  const g = parseInt(corHex.substring(2, 4), 16);
  const b = parseInt(corHex.substring(4, 6), 16);

  const brilho = (r * 0.299 + g * 0.587 + b * 0.114);

  return brilho > 150;
}
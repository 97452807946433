export const statesList = [
  { nome: "MG" },
  { nome: "AC" },
  { nome: "AL" },
  { nome: "AP" },
  { nome: "AM" },
  { nome: "BA" },
  { nome: "CE" },
  { nome: "DF" },
  { nome: "ES" },
  { nome: "GO" },
  { nome: "MA" },
  { nome: "MT" },
  { nome: "MS" },
  { nome: "PA" },
  { nome: "PB" },
  { nome: "PR" },
  { nome: "PE" },
  { nome: "PI" },
  { nome: "RJ" },
  { nome: "RN" },
  { nome: "RS" },
  { nome: "RO" },
  { nome: "RR" },
  { nome: "SC" },
  { nome: "SP" },
  { nome: "SE" },
  { nome: "TO" },
]
